import React from "react";
import Box from "@mui/material/Box";
import DomainInputForm from "../components/DomainInputForm";

function EntryPage({ onResults }) {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <DomainInputForm onResults={onResults} />
    </Box>
  );
}

export default EntryPage;
