import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Typography,
  Alert,
  Container,
} from "@mui/material";

function DomainInputForm({ onResults }) {
  const [domain, setDomain] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiKey = process.env.REACT_APP_BACKEND_API_KEY;

  const isValidDomain = (domain) => {
    if (domain !== domain.toLowerCase()) {
      return false;
    }

    return /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test(
      domain
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const lowerCaseDomain = domain.toLowerCase();

    if (!lowerCaseDomain || !isValidDomain(lowerCaseDomain)) {
      setError("Please enter a valid domain in lowercase.");
      setLoading(false);
      setTimeout(() => {
        setError(null);
      }, 2000);
      return;
    }

    setError(null);
    try {
      const response = await axios.post(
        apiUrl,
        { domain: lowerCaseDomain },
        {
          headers: {
            "API-KEY": apiKey,
          },
        }
      );
      onResults(response.data);
    } catch (err) {
      setError(
        `Error checking DNS: ${err.response?.statusText || err.message}`
      );
      setTimeout(() => {
        setError(null);
      }, 2000);
    }
    setLoading(false);
  };

  return (
    <Container
      maxWidth={false}
      sx={{ maxWidth: "1760px", margin: "auto", padding: 2 }}
    >
      <Box
        sx={{ width: "100%", margin: "auto", padding: 2, textAlign: "center" }}
      >
        <Typography
          variant="h1"
          sx={{ fontSize: 26, fontWeight: 700, marginBottom: 2, width: "100%" }}
        >
          DNS Records Checker - Check Your Domain's DNS Health
        </Typography>
        <Typography
          variant="h2"
          sx={{ fontSize: 17, fontWeight: 500, marginBottom: 4, width: "100%" }}
        >
          Enter a domain name below to get a comprehensive DNS records report
          and assess the health of your DNS setup.
        </Typography>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          <TextField
            label="Enter domain name"
            variant="outlined"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            disabled={loading}
            fullWidth
            error={Boolean(error)}
            helperText={
              error && !error.startsWith("Error checking DNS") ? (
                <span style={{ color: "#B00020" }}>{error}</span>
              ) : (
                ""
              )
            }
            sx={{
              "& .MuiInputLabel-root": {
                color: error ? "#B00020" : "#3E52B5",
              },
              "& .MuiInputLabel-shrink": {
                color: error ? "#B00020" : "#3E52B5",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: error ? "#B00020" : "#3E52B5",
                },
                "&:hover fieldset": {
                  borderColor: error ? "#B00020" : "#3E52B5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: error ? "#B00020" : "#3E52B5",
                },
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{
              backgroundColor: "#3E52B5",
              "&:hover": {
                backgroundColor: "#1A237E",
              },
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Check DNS"}
          </Button>
          {error && error.startsWith("Error checking DNS") && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </form>
        <Typography variant="body1" sx={{ marginTop: 2, width: "100%" }}>
          This project, created by{" "}
          <a
            href="https://bmoskovsky.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Boris Moskovsky
          </a>{" "}
          in 2006, is free for everyone to use. It’s built with Python and React
          JS using Material UI, and it’s hosted on AWS.
        </Typography>
      </Box>
    </Container>
  );
}

export default DomainInputForm;
