import React, { useState, useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import axios from "axios";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Header from "../components/Header";
import ResultsDisplay from "../components/ResultsDisplay";
import Footer from "../components/Footer";

function ResultsPage({ onReset }) {
  const { domain } = useParams();
  const [currentResults, setCurrentResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const [validDomain, setValidDomain] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiKey = process.env.REACT_APP_BACKEND_API_KEY;

  const isValidDomain = (domain) => {
    return /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test(
      domain
    );
  };

  useEffect(() => {
    if (!isValidDomain(domain)) {
      setValidDomain(false);
      setLoading(false);
      return;
    }

    const fetchDNSResults = async () => {
      try {
        const response = await axios.post(
          apiUrl,
          { domain },
          {
            headers: { "API-KEY": apiKey },
          }
        );
        setCurrentResults(response.data);
      } catch (error) {
        console.error("Error fetching DNS records:", error);
        setValidDomain(false);
      } finally {
        setLoading(false);
      }
    };

    fetchDNSResults();
  }, [domain, apiUrl, apiKey]);

  if (loading) {
    return (
      <Typography variant="h5">Loading DNS records for {domain}...</Typography>
    );
  }

  if (!validDomain) {
    return <Navigate to="/404" />;
  }

  return currentResults ? (
    <>
      <Header />
      <Container
        maxWidth={false}
        sx={{
          maxWidth: "1760px",
          flexGrow: 1,
          padding: "0 2%",
        }}
      >
        <ResultsDisplay results={currentResults} />
        <button
          onClick={onReset}
          style={{ margin: "20px", padding: "10px 20px" }}
        >
          Search Another Domain
        </button>
      </Container>
      <Footer />
    </>
  ) : (
    <Typography variant="h5">
      No results found for {domain}. Please return to the homepage.
    </Typography>
  );
}

export default ResultsPage;
