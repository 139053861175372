import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import "../App.css";

function ResultsDisplay({ results }) {
  if (!results) return null;

  const { domain, results: recordResults, health, issues } = results;

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        DNS Check Results for {domain}
      </Typography>
      <Typography variant="h6" component="p" gutterBottom>
        Overall Health: {health}
      </Typography>
      <Grid container spacing={2}>
        {Object.entries(recordResults).map(
          ([recordType, { status, value }]) => (
            <Grid item xs={12} sm={6} md={4} key={recordType}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{recordType} Record</Typography>
                  <Typography>Status: {status}</Typography>
                  <Typography>
                    Value: {Array.isArray(value) ? value.join(", ") : value}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )
        )}
      </Grid>
      {issues.length > 0 && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h5" component="h3" gutterBottom>
            Issues
          </Typography>
          <List>
            {issues.map((issue, index) => (
              <ListItem key={index}>
                <ListItemText primary={issue} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
}

export default ResultsDisplay;
