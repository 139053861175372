import React from "react";
import { Box, Typography } from "@mui/material";
import "../App.css";

function Footer() {
  return (
    <Box
      sx={{
        textAlign: "center",
        padding: 2,
        backgroundColor: "primary.main",
        color: "white",
        marginTop: "auto",
      }}
    >
      <Typography variant="body1">
        Developed with a passion for technology by{" "}
        <a
          href="https://bmoskovsky.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Boris Moskovsky
        </a>{" "}
        in 2006
      </Typography>
    </Box>
  );
}

export default Footer;
