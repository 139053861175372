import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function NotFound() {
  return (
    <Box sx={{ textAlign: "center", padding: 2 }}>
      <Typography variant="h4">404 - Page Not Found</Typography>
      <Typography variant="body1">
        The page you are looking for does not exist. Please check the URL or
        return to the homepage.
      </Typography>
    </Box>
  );
}

export default NotFound;
