import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import EntryPage from "./pages/EntryPage";
import ResultsPage from "./pages/ResultsPage";
import NotFound from "./components/NotFound";

function App() {
  const navigate = useNavigate();

  const handleResults = (data) => {
    navigate(`/${data.domain}`);
  };

  const handleReset = () => {
    navigate("/");
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<EntryPage onResults={handleResults} />} />
        <Route
          path="/:domain"
          element={<ResultsPage onReset={handleReset} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
